/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ToastContainer } from 'react-toastify';

import { Layout, FormContainer, StyledLabel, SectionContainer, CustomForm, StyledInput, SendMessage, CustomButton, TextAreaContainer, TextAreaLabel, TextAreaInput  } from './styles';

import withValidations from '../WithValidations';
import Waiting from '../Waiting';

import { saveContactUsInfo, submitContactForm } from '../../redux/actions';
import ContactSuccessPopUp from "../ContactSuccessPopUp";

const contactUsFormData =  [
  {
    "name": "firstName",
    "label": "First Name",
  },
  {
    "name": "lastName",
    "label": "Last Name",
  },
  {
    "name": "email",
    "label": "Email Address",
  },
  {
    "name": "phone",
    "label": "Phone",

  },
  {
    "name": "title",
    "label": "Job Title "
  },
  {
    "name": "companyName",
    "label": "Company Name"
  },
  {
    "name": "industries",
    "label": "Industry"
  },
  {
    "name": "size",
    "label": "Company Size"
  },

];

class ContactUsForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      contactStatus: false
    }
  }

  submitForm = (e) => {
    e.preventDefault();
    const { submitContactForm, history } = this.props;
    submitContactForm(history);
    this.setState({contactStatus: true});
  };

  onBlur = (e) => {
    const { saveContactUsInfo, errors } = this.props;
    if(e.target.value.trim() !== '' && !errors[e.target.name]) saveContactUsInfo(e.target.name, e.target.value);
  };

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if(charCode === 46) evt.preventDefault();
    if(charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  };

  onClosePopUp = () => {
    const { history } = this.props;
    this.setState({contactStatus: false});
    history.push('/');
  }

  render() {
    const { contactUsInfo, onChange, errors, isLoading, touched, contactSuccess, contactPopUpStatus } = this.props;
    const { contactStatus } = this.state;
    return (
      <Layout>
        {isLoading && <Waiting />}
        <FormContainer>
          <SendMessage>Send us Message</SendMessage>
          <CustomForm onSubmit={(e) => this.submitForm(e)} id='contact-form'>
            {
              contactUsFormData.map((field, index) => (

                <SectionContainer key={field.name + index} fullWidth={field.label === 'Your Message'} padding={index % 2 == 0}>
                  <StyledLabel>{field.label}</StyledLabel>
                  <StyledInput
                    type='text'
                    name={field.name}
                    onBlur={(e) => this.onBlur(e)}
                    onChange={(e) => onChange(e)}
                    defaultValue={_.get(contactUsInfo, field.name, '')}
                    onKeyPress={(e) => (field.name ==='phone' || field.name === 'size') && this.isNumberKey(e)}
                    maxLength={(field.name === 'phone' || field.name === 'size') ? 12 : -1}
                    required
                  />
                </SectionContainer>

              ))
            }
            <TextAreaContainer fullWidth={1}>
              <TextAreaLabel>Your Message</TextAreaLabel>
              <TextAreaInput
                name={"message"}
                onBlur={(e) => this.onBlur(e)}
                onChange={(e) => onChange(e)}
                rows={4}
                resize={1}
                data-gramm_editor="false"
              />
            </TextAreaContainer>
            <div className="buttonContainer">
              <CustomButton
                type="submit"
                disabled={Object.keys(_.omitBy(errors, _.isNil)).length > 0 ||
                (Object.keys(touched).length < 9)
                }
              >Get in Touch</CustomButton>
            </div>
          </CustomForm>
        </FormContainer>
        <ContactSuccessPopUp
          showModal={contactStatus && contactPopUpStatus}
          onClose={this.onClosePopUp}
          title={"Message Received!"}
          subContent={contactSuccess}
        />
        <ToastContainer />
      </Layout>
    )
  }
}

ContactUsForm.propTypes = {
  contactUsInfo: PropTypes.object,
  saveContactUsInfo: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  submitContactForm: PropTypes.func.isRequired,
  contactError: PropTypes.string,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  contactSuccess: PropTypes.string,
  contactPopUpStatus: PropTypes.bool
};

const mapStateToProps = (state) => ({
  contactUsInfo: state.auth.contactUsInfo,
  contactError: state.auth.contactError,
  isLoading: state.auth.isLoading,
  contactSuccess: state.auth.contactSuccess,
  contactPopUpStatus: state.auth.contactPopUpStatus,
});

const mapDispatchToProps = (dispatch) => ({
  saveContactUsInfo: (name, value) => dispatch(saveContactUsInfo(name, value)),
  submitContactForm: (history) => dispatch(submitContactForm(history))
});

export default withValidations(connect(mapStateToProps, mapDispatchToProps)(ContactUsForm));