import styled from 'styled-components';

const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';


const Layout = styled.div`
  float: left;
  width: 100%;
  padding: 60px 30px 30px 30px;
  font-family: ${RegularFont};
  
  @media (max-width: 500px) {
    padding: 60px 10px 30px;
  }
`;

const PageTitle = styled.h1`
  font-size: 32px;
  line-height: 35px;
  color: #2b281e;
  font-family: ${BoldFont};
  text-align: center;
  margin: 0;
  font-weight: 700;
`;

const ContactUsInformation = styled.p`
  font-size: 19px;
  line-height: 35px;
  color: #2b281e;
  font-family: ${RegularFont};
  font-weight: 400;
  text-align: center;
  margin: 8px 0 50px;
  padding: 0 100px;
  @media (max-width: 600px) {
    padding: 0 13vw;
  }
`;

const ContactUsSection = styled.div`
  width: 100%;
  padding-top: 310px;
  padding-bottom: 110px;
  float: left;
  background-color: #f6f6f8;

  @media (max-width: 760px) {
    padding-top: 378px;
  }

  @media (max-width: 500px) {
    padding-top: 662px;
  }

  @media (max-width: 400px) {
    padding-top: 700px;
  }
  
  @media (max-width: 350px) {
    padding-top: 750px;
  }
  
  @media (min-width: 1600px) {
        padding-left: calc(calc(100% - 1600px) / 2);
        padding-right: calc(calc(100% - 1600px) / 2);
  }
`;

const ContactDetails = styled.div`
   margin: 0 auto
   display: block;
   padding: 0 200px;
   float: left;
   width: 100%
   display: flex;
   justify-content: center;
   @media (max-width: 1024px) {
    padding: 0 15vw;
   }
   
   @media (max-width: 800px) {
    padding: 0 12vw;
   }
   
   @media (max-width: 600px) {
    padding: 0 10vw;
   }
   
   @media (max-width: 500px) {
    padding: 0;
   }
  
`;
const ContactSalesTeam = styled.div`
    width: 85%;
    float: left;
    background-image: linear-gradient(0deg, #002f45 0%, #006581 100%);
    min-height: 230px;
    @media (max-width: 1000px) {
        width: 100%;
    }
    @media (min-width: 1420px) {
        width: 65%;
    }    
`;


const TextContainer = styled.div`
    font-size: 25px;
    color: #f6f6f8;
    font-family: ${RegularFont}
    font-weight: 400;
    width: 60%;
    padding: 70px 40px 40px 50px;
    float: left;
    
    & > p {
      margin-bottom: 0px;
      font-size: 28px;
      @media (max-width: 400px) {
        text-align: center;
      }
      
    }
    
    @media (max-width: 900px) {
      margin: 0 auto;
      padding-right: 0
    }
    
    @media (max-width: 400px) {
      padding: 40px 0px;
    }
    
    @media (max-width: 600px) {
      padding-left: 30px;
    }
    
    @media (max-width: 700px) {
      text-align: center;
      width: 100%
      padding-left: 0;
      padding-top: 50px;
    }

`;

const ImageContainer = styled.div`
   width: 40%;
   float: left;
   text-align: right;
   padding-right: 65px;
   & > img {
    width: 155px;
   }
   
   @media (max-width: 600px) {
      padding-right: 30px;
   }
   
   @media (max-width: 700px) {
      width: 100%
      text-align: center;
      padding-bottom: 50px;
      padding-right:  0px;
   }
`;

const LinkContainer = styled.div`
   display: inline-block;
   & > a {
    display : inline-block;
   }
   
   & > a > img {
      width: 30px;
   }
   
   @media (max-width: 600px) {
      & > a > img {
        width: 30px
      }
   }
   
   @media (max-width: 400px) {
        width: 100%;
        text-align: center;
   }
`;

const StyledLink = styled.a`
  color: #f6f6f8;;
  margin-top: 30px;
  cursor: pointer;
  display: inline-block;
  font-family: ${RegularFont}
  font-size: 16px;
  margin-left: 10px;
  
  &:focus, &:hover, &:active {
    color: white;
    text-decoration: none;
  }
  
`;

export { Layout, PageTitle, ContactUsInformation, ImageContainer, ContactUsSection, ContactDetails, StyledLink, ContactSalesTeam, TextContainer, LinkContainer};