import styled from 'styled-components';


const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const Layout = styled.div`
  max-width: 500px;
  width: 100%
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 0
  @media (max-width: 600px) {
    width: 80%;
  } 
`;

const FormContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px 0 5px 0;
  margin: 20px auto;
  background-color: white;
`;

const StyledLabel = styled.label`
  float: left;
  display: block;
  font-size: 14px;
  color: #0068b4;
  font-family: ${MediumFont};
  font-weight: 500;
  font-weight: normal;
  position: relative;
  pointer-events: none;
  
`;

const StyledInput = styled.input`
  background-color: #f6f6f8;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  font-size: 14px;
  margin-bottom: 0;
  height: 40px;
  width: 100%;
  padding-left: 15px;
  
  &::placeholder {
    font-size: 14px;
    color: #2b281e;
    font-family: ${RegularFont};
    font-weight: 400;
    vertical-align: middle;
  }
  
`;

const SectionContainer = styled.div`
  width: ${({ fullWidth }) => fullWidth ? '100%' : '50%'};
  padding: ${({padding}) => padding ? '0px 12px 20px 40px' : '0px 40px 20px 12px'};
  display: inline-block;
  
  @media (max-width: 500px) {
    width: 100%;
    padding: 0 10% 20px 10%
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TextAreaContainer = styled.div`
    width: 100%;
    padding: 5px 40px 20px 40px;
    display: block;
    @media (max-width: 500px) {
        padding: 0 10% 20px 10%;
    }
`;

const TextAreaLabel = styled.label`
   display: block;
   font-size: 14px;
   color: #0068b4;
   font-family: ${MediumFont};
   font-weight: 500;
   position: relative;
   pointer-events: none;
   text-align: left;
   
`;

const TextAreaInput = styled.textarea`
     display: block;
     width: 100%
     background-color: #f6f6f8;
     border: none;
     padding: 15px;
     resize: ${({ resize }) => resize ? 'none' : 'vertical'};
     outline: none;
     
   &::placeholder {
    font-size: 14px;
    color: #2b281e;
    font-family: ${RegularFont};
    font-weight: 400;
    vertical-align: middle;
   }
`;

const CustomForm = styled.form`
  width: 100%;
  background-color: white;
  margin-top: 30px;
  padding-bottom: 30px;
  .buttonContainer {
    padding: 0px 40px;
    width: 100%;
  }
  @media (max-width: 500px) {
  .buttonContainer {
      padding: 0 10% 0px 10%;
  }
  }
`;

const SendMessage = styled.p`
  font-size: 19px;
  text-align: center;
  margin: 20px 0 50px;
  font-family: ${BoldFont};
  letter-spacing: 2px;
  line-height: 14px;
  color: #2b281e;
  text-transform: uppercase;
`;

const CustomButton = styled.button`
  width: 100%;
  height: 45px;
  text-align: center;
  margin-top: 5px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  
  font-size: 16px;
  line-height: 34px;
  color: #ffffff;
  font-family: ${MediumFont};
  font-weight: normal;
  background-color: #0273b9;
  border-radius: 37px;
  border: none;
  
  &:focus, &:active, &:hover {
    outline: none;
  }
 
`;

export { Layout, FormContainer, StyledInput, StyledLabel, SectionContainer, CustomForm, SendMessage, CustomButton, TextAreaContainer, TextAreaLabel, TextAreaInput};