import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {PageTitle, ContactUsInformation, ContactUsSection, ContactDetails, StyledLink, ContactSalesTeam, TextContainer, LinkContainer, ImageContainer} from './styles';
import ContactUsFormV2 from '../ContactUsFormV2';
import styled from "styled-components";
import {COMPANY_NAME} from "../../utils/config";

const MediumFont = 'Rubik-Regular';
const RegularFont = 'Rubik-Light';

const HeroContainer = styled.div`
    position: relative;
    background: url(../../images/ContactV2/Contact_bg.png);;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    padding: 90px 50px;
    float: left;
    min-height: 785px;
    background-color: #f6f6f8;
    
    @media (min-width: 320px) and (max-width: 480px) {
        padding: 20px;
    }
    @media (max-width: 1000px) {
      padding: 60px 6vw;
    }
    @media (max-width: 736px) {
      padding: 50px 3vw;
    }
    @media (max-width: 500px) {
        padding: 80px 10px;
    }
    @media (min-width: 1600px) {
      padding-left: calc(calc(100% - 1500px) / 2);
      padding-right: calc(calc(100% - 1500px) / 2);
    }
`;
const HeroTitleContainer = styled.div`
    margin: ${({margin}) => margin ? margin : '0 auto'};
    padding: 20px;
    text-align: center;
    transform: ${({margin}) => margin ? 'translateY(70px)' : 'none'};
    
    & > p {
        font-size: 32px;
        color: #ffffff;
        font-family: ${MediumFont};
        font-weight: 400;
        text-align: center;
    }
    
    @media (max-width: 500px) {
        padding: 0
    }
    
`;

const HeroSubTitle = styled.div`
    font-size: 17px;
    color: #ffffff;
    font-family: ${RegularFont};
    font-weight: 400;
    text-align: center;
    padding: 10px 17px;
    margin: 20px auto;
    max-width: 800px;
    @media (max-width: 1024px) {
      padding: 0 9vw;
    }
    
    @media (max-width: 800px) {
      padding: 0 4vw;
    }
   
`;

class Contact extends PureComponent {
  showText = (showThankYou) => {
    const showThankyouText = [
      "We have received your request, and you would be contacted shortly.",
      `${COMPANY_NAME}'s mission is to help create a culture of wellbeing so employees can thrive. By making work less stressful and teams more connected, ${COMPANY_NAME} is reimagining engagement, recognition and wellness for organizations.`
    ]
    return showThankYou ? <HeroTitleContainer margin={'50px auto 0'}>
      <p>Thank You for reaching out to us!</p>
      {showThankyouText.map((data, index) =>(<HeroSubTitle key={index}>
        {data}
      </HeroSubTitle>))}
    </HeroTitleContainer> : <HeroTitleContainer>
      <p>Reach out to us we&apos;d love to hear from you</p>
      <HeroSubTitle>
        We take our commitment to our clients seriously. If you need our help or have questions about the platform, please do not hesitate to contact us.
      </HeroSubTitle>
    </HeroTitleContainer>
  };
  render() {
    const{showThankYou} = this.props;
    return (
      <div>
        <HeroContainer>
          {this.showText(showThankYou)}
          {showThankYou ? null : <ContactUsFormV2 history={this.props.history} />}
        </HeroContainer>
        {showThankYou ? null :
          <ContactUsSection>
            <PageTitle>
              Contact us
            </PageTitle>
            <ContactUsInformation>
              Questions, bug reports, feedback, feature requests — we’re here for it all.
            </ContactUsInformation>
            <ContactDetails>
              <ContactSalesTeam>
                <TextContainer>
                  <p>Contact our sales team</p>
                  <LinkContainer>
                    <a href='mailto:sales@woliba.io'>
                      <img src='/public/images/ContactV2/icon_mail.png' alt='email' />
                    </a>
                    {
                      COMPANY_NAME === 'Training Amigo' ?
                        <StyledLink href="mailto:sales@trainingamigo.com">
                        Sales@trainingamigo.com
                        </StyledLink> :
                        <StyledLink href="mailto:sales@woliba.io">
                        sales@woliba.io
                        </StyledLink>
                    }
                  </LinkContainer>
                </TextContainer>
                <ImageContainer>
                  <img src = '/public/images/ContactV2/CustomerService.png' />
                </ImageContainer>
              </ContactSalesTeam>
            </ContactDetails>
          </ContactUsSection>}
      </div>

    )
  }
}

Contact.propTypes = {
  history: PropTypes.object.isRequired,
  showThankYou: PropTypes.bool
};

export default Contact;